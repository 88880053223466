import React from "react";
import { Link } from "gatsby";

import StaticLayout from "../components/StaticLayout";
import imgSrc from "../images/openingsdansen.jpg";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-11"
        className="post-11 post type-post status-publish format-standard hentry category-geen-categorie"
      >
        <header className="entry-header">
          <h1 className="entry-title">Openingsdans</h1>
          <div className="entry-meta" />
          {/* .entry-meta */}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <p>
            <img
              className="size-medium wp-image-175 aligncenter"
              src={imgSrc}
              alt=""
              width={244}
              height={300}
            />
          </p>
          <p>
            Proficiat! Jullie gaan trouwen! Bij de organisatie van een bruiloft
            komt heel wat kijken. Ook over de openingsdans breken veel koppels
            zich het hoofd. Maar maak je geen zorgen, bij ons kan je terecht
            voor een dans op maat, met aandacht voor jouw persoonlijke smaak. We
            zoeken naar de perfecte muziek en leren je hoe je daar een
            spetterende openingsdans op danst. Zo zorgen we er samen voor dat je
            straalt op jullie grote dag!
          </p>
          <p>
            Staat je trouwdag voor de deur? Na één of enkele privélessen doe je
            je familie en vrienden versteld staan met je danskunsten! Ieder
            koppel is anders. Bij ons kan je op je eigen tempo ontdekken hoe je
            ideale openingsdans eruitziet, ook als je daar nu nog geen idee van
            hebt.&nbsp; Zo is het mogelijk om de choreografie af te stemmen op
            jullie wensen en mogelijkheden.
          </p>
          <p>
            Heb je nog wat tijd voor je de dansvloer op moet? Dan raden we je
            aan om deel te nemen aan onze groepslessen. Zo leer je in stappen
            verschillende dansstijlen, en heb je de luxe om te kiezen welke het
            beste bij jullie past. Natuurlijk zijn ook vrienden, vriendinnen,
            ouders, broers en zussen… zeer welkom om samen een dans te leren
            voor jullie trouwdag.
          </p>
          <p>Heb je nog vragen? Neem dan gerust contact met ons op!</p>
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1920 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1925,0" />
        </svg>
      </div>
      <nav className="navigation post-navigation" role="navigation">
        <h2 className="screen-reader-text">Berichtnavigatie</h2>
        <div className="nav-links">
          <div className="nav-previous">
            <Link to="/">Terug naar de startpagina</Link>
          </div>
        </div>
      </nav>
    </main>
  </StaticLayout>
);

export default Page;
